@media print {

  header {
    display: none !important;
  }

  .no-print {
    display: none !important;
  }
}
