.rt-resizable-header-content {
  /*height: 17px;*/
  color: #888a8c;
  font-family: "prometo", "Montserrat", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: none;
}
.ReactTable .rt-tfoot {
  box-shadow: none;
  border-top: 1px solid #cbcbcb !important;
  color: #777777;
  font-family: "prometo", "Montserrat", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 10px;
}

/*.ReactTable .rt-tfoot {*/
/*  scroll-padding-bottom: 16px;*/
/*}*/

.ReactTable .rt-thead p{
  margin-block-start: 0;
  margin-block-end: 0;
}

.ReactTable .rt-tbody {
  overflow-x: hidden;
/*!*  overflow-y: scroll; !* has to be scroll, not auto *!*!*/
/*!*  -webkit-overflow-scrolling: touch;*!*/
}

.ReactTable .rt-tbody .rt-td {
  border-right: none;
  color: #888a8c;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  /*line-height: 35px;*/
}

  .ReactTable .rt-thead .rt-tr {
  white-space: normal;
  text-align: left;
  margin-top: 12px;
  /*text-transform: uppercase;*/
}

.ReactTable .rt-thead.-header {
  border-bottom: 1px solid #cbcbcb !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 60px;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #cbcbcb !important;
}

.ReactTable .rt-head:focus, .rt-th:focus {
  outline: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: #e5e5e5;
}

.ReactTable .rt-tr:active {
  background-color: #C2E3FF;
}

.ReactTable .rt-noData {
  background-color: transparent;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  z-index: 0;
}